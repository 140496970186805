<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.43586 2.61087L3.53467 5.33395V10.7302L8.43586 13.4527L13.3371 10.7302V5.33395L8.43586 2.61087ZM14.8371 5.11389V10.9493C14.8371 11.1504 14.7833 11.3481 14.6813 11.5214C14.5793 11.6948 14.4327 11.8377 14.2568 11.9352L8.98278 14.8648C8.81557 14.9575 8.62707 15.0064 8.43586 15.0064C8.24465 15.0064 8.0566 14.9578 7.88939 14.8651L2.61494 11.9352C2.43901 11.8377 2.29242 11.6948 2.19038 11.5214C2.08839 11.3481 2.03464 11.1507 2.03467 10.9497V5.11389C2.03491 4.91304 2.08883 4.71501 2.19085 4.542C2.29278 4.36915 2.43901 4.22666 2.61444 4.12926L7.88889 1.1988C8.0561 1.10606 8.24466 1.05713 8.43586 1.05713C8.62706 1.05713 8.81512 1.10579 8.98233 1.19852L14.2568 4.12898C14.4322 4.22638 14.5789 4.36915 14.6809 4.542C14.7829 4.71501 14.8368 4.91304 14.8371 5.11389Z" fill="currentColor"/>
    <path d="M8.897 8.65499L14.4737 5.59264L13.7517 4.27783L8.35594 7.24083L3.38979 4.48143C3.32774 4.44702 3.25778 4.42941 3.18684 4.43033C3.1159 4.43126 3.04642 4.45069 2.98529 4.48671C2.92417 4.52273 2.8735 4.57408 2.83831 4.63569C2.80312 4.6973 2.78463 4.76703 2.78467 4.83798V10.697C2.78463 10.7697 2.80408 10.8412 2.84098 10.9039C2.87788 10.9666 2.9309 11.0183 2.99452 11.0535L8.29188 13.9963C8.35393 14.0307 8.42388 14.0483 8.49483 14.0474C8.56577 14.0465 8.63525 14.027 8.69637 13.991C8.7575 13.955 8.80817 13.9036 8.84335 13.842C8.87854 13.7804 8.89703 13.7107 8.897 13.6397V8.65499Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCube'
}
</script>
